<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo d-flex align-items-center">
        <gameguru-logo />
        <h2 class="brand-text text-primary ml-1">
          GAMEGURU
          <small class="d-block">Store</small>
        </h2>
      </b-link>
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <b-col
        id="googleLogin"
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 text-center"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to Gameguru Store! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign in with your <b>Gameguru Google account</b> and start the adventure 🚀
          </b-card-text>

          <div
            id="google-signin-button"
            v-google-signin-button="client_id"
          />
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import GameguruLogo from '@/components/GameguruLogo.vue'
import {
  BRow, BCol, BLink, BCardText, BCardTitle, BImg, BButton,
} from 'bootstrap-vue'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GoogleSignInButton from '@/views/auth/googleSignInButton'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    GameguruLogo,
    ToastificationContent,
  },
  directives: {
    GoogleSignInButton,
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/not-authorized.svg'),
      client_id: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    googleLogin(credential = null) {
      if (credential === null) {
        return null
      }
      const data = {
        provider: 'google',
        tokenId: credential,
      }

      useJwt.login(data).then(res => {
        const authData = {
          ...res.data.data,
          accessToken: res.data.data.accessToken,
        }
        this.$store.dispatch('auth/handleLogin', authData)
        useJwt.setToken(res.data.data.accessToken)
        this.$ability.update(authData.ability)
        this.$router.replace(localStorage.getItem('toPath') || '/')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error! Please contact administrator',
            icon: 'InfoIcon',
            text: error.response,
            variant: 'danger',
          },
        })
        return null
      })
    },
  },
  head: {
    script: [
      { src: 'https://accounts.google.com/gsi/client', async: true, defer: true },
    ],
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
