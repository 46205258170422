import Vue from 'vue'

export default Vue.directive('google-signin-button', {
  bind(el, binding, vnode) {
    const clientId = binding.value
    Vue.loadScript('https://accounts.google.com/gsi/client')
      .then(() => {
        window.google.accounts.id.initialize({
          client_id: clientId,
          plugin_name: 'vue-google-signin-button',
          ux_mode: 'popup',
          callback: data => {
            if (data.credential) vnode.context.googleLogin(data.credential)
            else vnode.context.googleLogin(null)
          },
        })
        window.google.accounts.id.renderButton(
          document.getElementById('google-signin-button'),
          { theme: 'outline', size: 'large' },
        )
        window.google.accounts.id.prompt()
      })
  },
})
